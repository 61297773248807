.sakubun {
  width: 95%;
  font-size: 2.5em;
  color: #333;
  border: 0px solid rgba(0, 0, 0, 0.2);
  position: relative;
  letter-spacing: 0.1em;
  line-height: 1.2em;
  padding: 5px;
  margin: auto;
  float: center;
}

.sakubun2 {
  width: 95%;
  font-size: 3em;
  color: #333;
  border: 0px solid rgba(0, 0, 0, 0.2);
  position: relative;
  letter-spacing: 0.1em;
  line-height: 0.5em;
  padding: 5px;
  margin: auto;
  float: center;
}

.sakubun::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2) 1px,
      transparent 0
    ),
    linear-gradient(to left, rgba(0, 0, 0, 0.2) 1px, transparent 0),
    linear-gradient(
      to bottom,
      transparent 11px,
      rgba(0, 0, 0, 0.2) 12px,
      transparent 0
    );
  background-size: 1.5em 2em;
  background-position: top left;
  background-origin: padding-box;
  margin-left: -5px;
  z-index: -1;
}

.sakubun::after {
  content: "";
  display: block;
  clear: both;
}

.sakubun p {
  margin: 0;
  margin-right: 0;
  margin-top: 0.3em;
}

.sakubun ruby {
  ruby-align: left;
}

.sakubun ruby > rt {
  font-size: 10px;
  letter-spacing: 0;
  text-align: justify;
  line-height: 1em;
  transform: translateX(0.4em) translateY(-1.1em);
  margin-bottom: 0.1em;
}

.grid {
  min-height: 360px;
  height: 360px;
}

.hyouki {
  /*上と下に水色の1pxの破線を引きます*/
}

.kana {
  /*上と下に水色の1pxの破線を引きます*/
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes ukiagaru2 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ukiagaru {
  0% {
    opacity: 0;
    top: 120%;
  }

  100% {
    opacity: 1;
    top: 115%;
  }
}

.box14_outer {
  /* ▼ inline-block時の定義 */
  letter-spacing: 0;
  /* 1行の高さを初期化   */
  line-height: 1;
  /* 文字間隔を初期化    */
  text-align: center;
  /* 横位置定義          */
}

.charactor {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 2em;
}

.very {
  animation: ukiagaru 1.5s ease;
  position: absolute;
  top: 115%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: #00cfe6;
}

.box14 {
  position: relative;
  width: 4em;
  height: 4em;
  padding: 0.2em;
  margin: 0.5em;
  vertical-align: middle;
  border-radius: 0.5em;
  background: #ffffff;
  box-shadow: 0px 0px 0px 10px #eeeeee;
  border: soloid 3px black;
  display: inline-block;
  color: #dddddd;
}

#center {
  margin-left: auto;
  margin-right: auto;
  color: #00cfe6;
  font-size: 2em;
}

.box14_typing {
  position: relative;
  width: 4em;
  height: 4em;
  padding: 0.2em;
  margin: 0.5em;
  vertical-align: middle;
  border-radius: 0.5em;
  background: #ffffff;
  box-shadow: 0px 0px 0px 10px #eeeeee;
  border: soloid 3px black;
  display: inline-block;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
}

.checked {
  color: #6ae349;
}

.notchecked {
  color: #eeeeee;
}

.typing {
  color: #00cfe6;
  animation: ukiagaru2 ease 2s infinite;
}

.box14_inside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-70%) translateX(-50%);
  -webkit-transform: translateY(-70%) translateX(-50%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
