body {
  background-color: #f0f0f0;
  background-image: url(img2/dot6.webp);
  background-size: 500px;

  margin: 0;

  font-family: rera, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
